<template>
    <div style="width: 97%; margin-left:3%;margin-top:15px; float: left; text-align: left">
        <a :href="'/#/checkFurtherList?id='+ this.projectId" style="width: 20%; float: left;text-align: left">
            <Icon type="ios-arrow-dropleft" size="25"/>
        </a>
        <div style="width: 63%; float: left;text-align: right;font-size: 1.105rem; font-weight: 500">
            <count-d v-bind:end-date="finishTime"></count-d>
            <!--<span v-if="formLeft.processType===0" style="color:#e9ab01">
                This application is pending . . .
            </span>
            <span v-else-if="formLeft.processType===1" style="color:#13AF5F">
                This application has been approved.
            </span>
            <span v-else style="color:#D13819">
                This application has been rejected.
            </span>-->
        </div>
        <Card style="width: 80%;margin-bottom:20px;padding:10px 5%;float: left;text-align:left; font-size:1.0125rem;margin-top: 30px">
            <div style="width: 100%;float: left;text-align:center;margin-top: 7px">
                <Avatar id="avator" icon="ios-person" size="80"/>
            </div>
            <div style="width: 100%;float: left;text-align: center; margin-top: 12px">
                <div style="width:100%; float:left;font-size: 1.025rem;font-weight: 500;">
                    Applicant: <span style="display: inline;font-weight: 400;font-size: 1rem">{{userName}}</span>
                </div>
                <div style="margin-top:10px;margin-bottom:20px;width:100%; float:left;font-size: 1.025rem;font-weight: 500;">
                    Credit points: <span style="display: inline;font-weight: 400;font-size: 1rem">{{creditPoint}}</span>
                </div>
            </div>
            <div style="width: 100%;float: left;margin-bottom: 30px">

                <div style="font-size: 1.025rem;font-weight: 600;display: inline; padding-right: 15px">
                    Summary
                </div>
                {{summary_advice}}
            </div>
        </Card>
        <div style="width: 100%;float:left;">
            <div style="font-size: 1.025rem;font-weight: 600;display: inline; padding-right: 15px">
                Project
            </div>
            <span style="display: inline">{{projectName}}</span>
        </div>
        <div style="width: 100%;float:left;margin-top: 10px">
            <div>
                <div class="introBlock1" v-if="tp1===1">
                    <div style="font-weight: 600; font-size: 1.025rem;width: 70%;float: left">
                        Abstract:
                    </div>
                    <div style="width: 80%;float: left;padding:10px 3%;margin-top:1rem;margin-bottom: 15px">
                        {{abstract}}
                    </div>
                    <Card v-if="tp1===1" style="padding:0px 5%;width: 80%;float: left;margin-bottom: 15px">
                        <div style="width: 100%;text-align: left;float: left;color: darkgray;font-size: 1.045rem;margin-bottom:10px;font-weight: 500">
                            <Icon type="ios-leaf"/>
                            {{form.abs_type}}
                        </div>
                        <div v-if="form.abs_type==='For more information'"
                             style="width: 100%;margin-top:15px;margin-bottom: 10px">{{form.abs_content}}
                        </div>
                        <div v-if="form.abs_type==='Modify'" style="width: 100%;margin-top:15px;margin-bottom: 10px">
                            {{form.abs_modify}}
                        </div>
                        <Divider>
                        </Divider>
<!--                        <div v-if="!form.status">-->
<!--                            &lt;!&ndash;                        <div style="width: 30%;float: left;margin-bottom:40px;margin-left: 10%"><Button long type="primary" @click="approve()"> Approve</Button></div>&ndash;&gt;-->
<!--                            <div style="width: 30%;float: left;margin-bottom:40px;margin-left: 20%">-->
<!--                                <Button long @click="confer1()"> Confer</Button>-->
<!--                            </div>-->
<!--                        </div>-->
                    </Card>
                </div>

                <div class="introBlock1" v-if="tp2===1">
                    <div style="font-weight: 600; font-size: 1.025rem;width: 70%;float: left">
                        Dependency:
                    </div>
                    <div style="width: 80%;float: left;padding:10px 3%;margin-top:1rem;margin-bottom: 15px">
                        {{dependencies}}
                    </div>
                    <Card v-if="tp2===1" style="padding:0px 5%;width: 80%;float: left;margin-bottom: 15px">
                        <div style="width: 100%;text-align: left;float: left;color: darkgray;font-size: 1.045rem;margin-bottom:10px;font-weight: 500">
                            <Icon type="ios-leaf"/>
                            {{form.dep_type}}
                        </div>
                        <div v-if="form.dep_type==='For more information'"
                             style="width: 100%;margin-top:15px;margin-bottom: 10px">{{form.dep_content}}
                        </div>
                        <div v-if="form.dep_type==='Modify'" style="width: 100%;margin-top:15px;margin-bottom: 10px">
                            {{form.dep_modify}}
                        </div>
                        <Divider>
                        </Divider>
<!--                        <div v-if="!form.status">-->
<!--                            &lt;!&ndash;                        <div style="width: 30%;float: left;margin-bottom:40px;margin-left: 10%"><Button long @click="approve()" type="primary"> Approve</Button></div>&ndash;&gt;-->
<!--                            <div style="width: 30%;float: left;margin-bottom:40px;margin-left: 20%">-->
<!--                                <Button long @click="confer2()"> Confer</Button>-->
<!--                            </div>-->
<!--                        </div>-->
                    </Card>
                </div>

                <div class="introBlock1" v-if="tp3===1">
                    <div style="width: 100%;float: left;margin-bottom: 15px">
                        <div style="font-weight: 600; font-size: 1.025rem;width: 70%;float: left">
                            Environment:
                        </div>
                        <div style="width: 80%;float: left;padding:10px 3%;margin-top:1rem;margin-bottom: 15px">
                            {{environment}}
                        </div>
                        <Card v-if="tp3===1" style="padding:0px 5%;width: 80%;float: left;margin-bottom: 15px">
                            <div style="width: 100%;text-align: left;float: left;color: darkgray;font-size: 1.045rem;margin-bottom:10px;font-weight: 500">
                                <Icon type="ios-leaf"/>
                                {{form.env_type}}
                            </div>
                            <div v-if="form.env_type==='For more information'"
                                 style="width: 100%;margin-top:15px;margin-bottom: 10px">{{form.env_content}}
                            </div>
                            <div v-if="form.env_type==='Modify'"
                                 style="width: 100%;margin-top:15px;margin-bottom: 10px">{{form.env_modify}}
                            </div>
                            <Divider>
                            </Divider>
<!--                            <div v-if="!form.status">-->
<!--                                &lt;!&ndash;                                <div style="width: 30%;float: left;margin-bottom:40px;margin-left: 10%">&ndash;&gt;-->
<!--                                &lt;!&ndash;                                    <Button long type="primary" @click="approve()"> Approve</Button></div>&ndash;&gt;-->
<!--                                <div style="width: 30%;float: left;margin-bottom:40px;margin-left: 20%">-->
<!--                                    <Button long @click="confer3()"> Confer</Button>-->
<!--                                </div>-->
<!--                            </div>-->
                        </Card>
                    </div>

                    <Modal v-model="modal1"
                           title="Give your advice"
                           @on-ok="ok1()"
                           @on-cancel="cancel()">
                        <div>
                            <div style="padding-top: 20px">
                                <p>
                                    Specific advice：
                                </p>
                                <Input v-model="further_reason1" placeholder="add more info"
                                       style="margin-top:10px; width: 80%; margin-left: 10%"/>
                            </div>
                        </div>
                    </Modal>
                    <Modal v-model="modal2"
                           title="Give your advice"
                           @on-ok="ok2()"
                           @on-cancel="cancel()">
                        <div>
                            <div style="padding-top: 20px">
                                <p>
                                    Specific advice：
                                </p>
                                <Input v-model="further_reason2" placeholder="add more info"
                                       style="margin-top:10px; width: 80%; margin-left: 10%"/>
                            </div>
                        </div>
                    </Modal>

                    <Modal v-model="modal3"
                           title="Give your advice"
                           @on-ok="ok3()"
                           @on-cancel="cancel()">
                        <div>
                            <div style="padding-top: 20px">
                                <p>
                                    Specific advice：
                                </p>
                                <Input v-model="further_reason3" placeholder="add more info"
                                       style="margin-top:10px; width: 80%; margin-left: 10%"/>
                            </div>
                        </div>
                    </Modal>
                    <Modal v-model="modal4"
                           title="Give your advice"
                           @on-ok="ok4()"
                           @on-cancel="cancel()">
                        <div>
                            <div style="padding-top: 20px">
                                <p>
                                    Specific advice：
                                </p>
                                <Input v-model="further_reason4" placeholder="add more info"
                                       style="margin-top:10px; width: 80%; margin-left: 10%"/>
                            </div>
                        </div>
                    </Modal>

                </div>
                <div class="introBlock1" v-if="tpPrice===1">
                    <div style="width: 100%;float: left;margin-bottom: 15px">
                        <div style="font-weight: 600; font-size: 1.025rem;width: 70%;float: left">
                            Price:
                        </div>
                        <div style="width: 30%;float: left">
                        </div>
                    </div>
                    <Card v-if="tpPrice===1" style="padding:0px 5%;width: 80%;float: left;margin-bottom: 15px">
                        <div style="width: 100%;text-align: left;float: left;color: darkgray;font-size: 1.045rem;margin-bottom:10px;font-weight: 500">
                            <Icon type="ios-leaf"/>
                            {{form.price_type}}
                        </div>
                        <div v-if="form.price_type==='For more information'"
                             style="width: 100%;margin-top:15px;margin-bottom: 10px">{{form.price_content}}
                        </div>
                        <div v-if="form.price_type==='Modify'"
                             style="width: 100%;margin-top:15px;margin-bottom: 10px">
                            <div class="table_unit"
                                 style="width: 100%;height:40px; font-size:1.0125rem; padding-top:10px;margin-bottom:20px;padding-left:3%;text-align:left;float:left; background-color: #F5F5F5">
                                <div style="width:35%; float:left;display:inline">
                                    Total Profit
                                </div>
                                <div style="width:35%;margin-left:3%;float:left;display:inline">
                                    Price
                                </div>
                            </div>
                            <div v-for="(item,index) in form.price_modify" class="table_unit"
                                 style="width:100%; float:left;">
                                <div class="table_unit" style="width:35%; float:left;display:inline">
                                    <div style="padding-left: 20px">
                                        {{item.split("---")[0]}}
                                    </div>
                                </div>
                                <div class="table_unit" style="width:35%; margin-left:3%;float:left;display:inline">
                                    <div style="padding-left: 15px">
                                        {{item.split("---")[1]}}
                                    </div>
                                </div>
                                <hr style="width:100%; float:left; margin-top:12px;height:1px; border:none; background-color:#F5F5F5">
                            </div>
                        </div>
                        <Divider>
                        </Divider>
                    </Card>

                </div>
                <div class="introBlock1" v-if="tp4===1">
                    <div style="width: 100%;float: left;margin-bottom: 15px">
                        <div style="font-weight: 600; font-size: 1.025rem;width: 70%;float: left">
                            Functions:
                        </div>
                        <div style="width: 30%;float: left">
                        </div>
                    </div>
                    <Card v-if="tp4===1" style="padding:0px 5%;width: 80%;float: left;margin-bottom: 15px">
                        <div style="width: 100%;text-align: left;float: left;color: darkgray;font-size: 1.045rem;margin-bottom:10px;font-weight: 500">
                            <Icon type="ios-leaf"/>
                            {{form.func_type}}
                        </div>
                        <div v-if="form.func_type==='For more information'"
                             style="width: 100%;margin-top:15px;margin-bottom: 10px">{{form.func_content}}
                        </div>
                        <div v-if="form.func_type==='Modify'"
                             style="width: 100%;margin-top:15px;margin-bottom: 10px">
                            <div class="table_unit"
                                 style="width: 100%;height:40px; font-size:1.0125rem; padding-top:10px;margin-bottom:20px;padding-left:3%;text-align:left;float:left; background-color: #F5F5F5">
                                <div style="width:35%; float:left;display:inline">
                                    Name
                                </div>
                                <div style="width:35%;margin-left:3%;float:left;display:inline">
                                    Description
                                </div>
                            </div>
                            <div v-for="(item,index) in form.func_modify" class="table_unit"
                                 style="width:100%; float:left;">
                                <div class="table_unit" style="width:35%; float:left;display:inline">
                                    <div style="padding-left: 20px">
                                        {{item.split("---")[0]}}
                                    </div>
                                </div>
                                <div class="table_unit" style="width:35%; margin-left:3%;float:left;display:inline">
                                    <div style="padding-left: 15px">
                                        {{item.split("---")[1]}}
                                    </div>
                                </div>
                                <hr style="width:100%; float:left; margin-top:12px;height:1px; border:none; background-color:#F5F5F5">
                            </div>
                        </div>
                        <Divider>
                        </Divider>
<!--                        <div v-if="!form.status">-->
<!--                            &lt;!&ndash;                        <div style="width: 30%;float: left;margin-bottom:40px;margin-left: 10%"><Button long @click="approve()" type="primary"> Approve</Button></div>&ndash;&gt;-->
<!--                            <div style="width: 30%;float: left;margin-bottom:40px;margin-left: 20%">-->
<!--                                <Button long @click="confer4()"> Confer</Button>-->
<!--                            </div>-->
<!--                        </div>-->
                    </Card>
                    <!--<div style="text-align: left;width: 100%;float: left">-->
                    <!--<div style="margin: 1rem 1rem; padding: 1rem 1rem; border: #000000 solid 0.0625rem; width: fit-content;">-->
                    <!--<img :src="hierarchyDiagramPath"/>-->
                    <!--</div>-->
                    <!--</div>-->

                </div>
<!--                <Card style="width: 80%;margin-bottom:20px;padding:10px 5%;float: left;text-align:center; font-size:1.0125rem;margin-top: 5px">-->
<!--                    <div v-if="!form.status">-->
<!--                        &lt;!&ndash;                        <div style="width: 30%;float: left;margin-bottom:40px;margin-left: 10%"><Button long @click="approve()" type="primary"> Approve</Button></div>&ndash;&gt;-->
<!--                        <div style="width: 30%;float: left;margin-bottom:40px;margin-left: 20%">-->
<!--                            <Button long @click="confer4()"> Confer</Button>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </Card>-->
                <Card style="width: 80%;margin-bottom:20px;padding:10px 5%;float: left;text-align:center; font-size:1.0125rem;margin-top: 5px">
                    <div v-if="!voteAlready" style="padding:0px 5%;width: 80%;float: left;margin-bottom: 0px">
                        <div style="width: 20%;float: left;margin-bottom:40px;margin-left: 10%">
                            <Button long type="primary" @click="myApprove()"> Approve</Button>
                        </div>
                        <div style="width: 20%;float: left;margin-bottom:40px;margin-left: 5%">
                            <Button @click="myReject()"> Reject</Button>
                        </div>
                        <div>
                            <Button style="width: 20%;float: left;margin-bottom:40px;margin-left: 15%" type="primary" @click="confer4">Confer
                            </Button>
                        </div>
                    </div>
                    <div v-else>
                        <div v-if="choice==='approve'">
                            You have already voted to approve.
                        </div>
                        <div v-if="choice==='reject'">
                            You have already voted to reject.
                        </div>
                        <div v-if="choice===''">
                            You didn't make a choice.
                        </div>
                    </div>
                </Card>

                <div v-show="voteAlready" style="width: 80%;float: left;margin-top: 30px">
                    <Divider></Divider>
                    <div>
                        <div style="width: 50%;float: left;font-size: 1.25rem;text-align: left;font-weight: 500; margin-bottom: 20px;display: inline">
                            Voting details
                            <Tag style="margin-left: 3px" type="dot" color="warning">{{voteState}}</Tag>
                        </div>
                        <div style="width: 50%;float: left;font-size: 1rem;text-align: right;font-weight: 500; margin-bottom: 20px;display: inline;">
                            <CountD v-bind:end-date="finishTime"></CountD>
                        </div>
                    </div>
                    <voting-pic v-bind:reject="reject" v-bind:pending="pending"
                                v-bind:approve="approve"></voting-pic>
                    <Divider></Divider>
                    <!--                        <div class="vote_information">-->
                    <!--                            <div class="vote_information_item">start time: {{startTime}}</div>-->
                    <!--                            <div class="vote_information_item">finish time: {{finishTime}}</div>-->
                    <!--                        </div>-->

                    <div style="width: 100%; float:left; margin-left:0% ;padding-left:  0%;padding-right:5%;padding-bottom: 50px">
                        <div style="width: 50%;float:left;padding-right:20%;margin-bottom: 20px;text-align: right">
                            <Input search placeholder="find center person"></Input>
                        </div>
                        <div style="width: 50%;float: right;margin-bottom: 40px;text-align: right">
<!--                            <Dropdown style="text-align: left">-->
<!--                                <Button type="primary">-->
<!--                                    Sort-->
<!--                                    <Icon type="ios-arrow-down"></Icon>-->
<!--                                </Button>-->
<!--                                <DropdownMenu slot="list">-->
<!--                                    <DropdownItem>Latest</DropdownItem>-->
<!--                                    <DropdownItem>Share</DropdownItem>-->
<!--                                </DropdownMenu>-->
<!--                            </Dropdown>-->
                            <Button style="margin-left: 12%" type="primary" @click="addComment">Add</Button>
                            <Modal v-model="commentModal"
                                   title="Leave a comment"
                                   @on-ok="commentOk"
                            >
                                <div>
                                    <div>
                                        <Input v-model="commentContent" type="textarea"
                                               placeholder="add more info...."
                                               style="margin-top:10px; width: 80%; margin-left: 10%"/>
                                    </div>
                                </div>
                            </Modal>
                        </div>
                        <Divider></Divider>
                        <div style="width: 100%; float: left">
                            <List item-layout="vertical">
                                <div v-for="item in voteCommentData" :key="item.title"
                                     style="width: 100%;float: left; min-height: 100px">
                                    <div style="width: 30%;padding-bottom:30px;float: left">
                                        <div style="text-align:center;width: 100%;float: left">
                                            <Avatar icon="ios-person" size="70"/>
                                        </div>
                                        <div style="text-align:center;width: 100%;float: left;margin-top: 20px;font-weight: 500;font-size: 1.025rem">
                                            {{item.userName}}
                                        </div>
                                    </div>
                                    <List item-layout="vertical">
                                        <ListItem
                                                style="text-align:left;width: 70%;float: left;word-wrap:break-word;word-break:normal;">
                                            {{ item.content }}
                                        </ListItem>
                                        <ListItem style="text-align:right;width: 70%;height:10px;float: left;">
                                            {{ item.time }}
                                        </ListItem>
                                    </List>
                                    <Divider></Divider>
                                </div>
                            </List>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Modal v-model="approveModal"
               title="Confirm"
               @on-ok="approveOk()"
               @on-cancel="cancel()">
            <div>
                <div style="padding-top: 20px">
                    Make sure that you approve his all application in this page.
                </div>
            </div>
        </Modal>
        <Modal v-model="rejectModal"
               title="Confirm"
               @on-ok="rejectOk()"
               @on-cancel="cancel()">
            <div>
                <div style="padding-top: 20px">
                    Make sure that you reject his all application in this page.
                </div>
            </div>
        </Modal>

    </div>
</template>

<script>
    import CountD from "./countD";
    import VotingPic from "./votingPic";


    export default {
        name: "checkFurtherInfo",
        components: {VotingPic, CountD},
        data() {
            return {
                reject: 0,
                pending: 0,
                approve: 0,
                voteAlready: false,
                approveModal: false,
                rejectModal: false,
                commentModal: false,
                commentContent: "",
                choice: "",
                voteCommentData: [],

                creditPoint: 0,
                projectName: 'projectName',
                start: true,
                projectId: '',
                jid: '',
                voteId: '',
                end_data: null,
                finishTime: null,
                userName: 'userName',


                modal1: false,
                modal2: false,
                modal3: false,
                modal4: false,



                abstract: 'The Visual Understanding Environment (VUE) project at Tufts\' Academic Technology department provides faculty and students with tools to successfully integrate digital resources into their teaching and learning. VUE provides a visual environment for structuring, presenting, and sharing digital information and an OKI-compliant software bridge for connecting to FEDORA-based digital repositories. Using VUE\'s concept mapping interface, faculty and students design customized semantic networks of digital resources drawing from digital libraries, local files and the Web.',
                environment: 'this project don\'t need any environment',
                dependencies: '',
                summary_advice: '',
                further_reason1: '',
                further_reason2: '',
                further_reason3: '',
                further_reason4: '',
                further_reasonPrice:"",
                //操控具体的展现
                tp1: 0,
                tp2: 0,
                tp3: 0,
                tp4: 0,
                tpPrice:0,
                form: {
                    abs_type: 'Modify',
                    abs_content: ' The Visual Understanding Environment (VUE) project at Tufts\' Academic Technology department provides faculty and students with tools to successfully integrate digital resources into their teaching and learning. ',
                    abs_modify: '',
                    env_type: 'For more information',
                    env_content: '',
                    env_modify: '',
                    dep_type: '',
                    dep_content: '',
                    dep_modify: '',
                    func_type: '',
                    func_content: '',
                    userId: '',
                    userName: '',
                    price_content:'',
                    price_modify:'',
                    price_type:'',

                },
                status: false,
                reply: '',
                replyUserId: '',
                functionList: [],
                functionNumber: 0,
                hierarchyDiagramPath: '',
                hierarchyList: []
            }
        },
        methods: {
            initial_data() {
                var Tself = this;
                var val = this.$route.query.jid;
                this.id = this.$route.query.jid;
                this.jid = this.$route.query.jid;
                this.$axios({
                    headers: {
                        'Content-Type': 'text/xml'
                    },
                    method: 'post',
                    url: '/moreInformation/findById',
                    data: val
                }).then(function (response) {
                    //添加内容
                    //这里要有申请的相关内容
                    console.log("-----------")
                    console.log(response)
                    let items = response.data.data.items;//获取FurtherInfo的所有信息
                    Tself.projectId = items.projectId;
                    Tself.projectName = items.projectName;
                    Tself.environment = items.environment;
                    Tself.userId = items.userId;
                    Tself.userName = items.username;
                    Tself.form.status = items.status;
                    Tself.form.userId = items.userId;
                    Tself.form.userName = items.username;
                    Tself.form.abs_content = items.moreAbstract;
                    Tself.form.abs_modify = items.modifyAbstract;
                    Tself.form.env_content = items.moreEnvironment;
                    Tself.form.env_modify = items.modifyEnvironment;
                    Tself.form.dep_content = items.moreDependency;
                    Tself.form.dep_modify = items.modifyDependency;
                    Tself.form.func_content = items.moreFunctions;
                    Tself.form.func_modify = items.functionList;
                    Tself.form.price_content = items.morePrices;
                    Tself.form.price_modify = items.priceList;
                    console.log(items.priceList)
                    Tself.summary_advice = items.summary;
                    Tself.popo();
                    if (items.status === 'untreated') {
                        Tself.rgType = 0;
                    } else {
                        Tself.rgType = 1;
                    }



                    //添加用户相关信息？credit
                    Tself.$axios.post(
                        "/user/findById",
                        Tself.userId,
                        {
                            headers: {
                                'Content-Type': 'text/xml'
                            }
                        }
                    ).then((response2) => {
                        let uf = response2.data.data.items;
                        Tself.creditPoint = parseInt(uf.credit);
                    }).catch((error) => {
                    })

                    //添加请求相关信息
                    Tself.$axios.post(
                        "/project/findById",
                        Tself.projectId,
                        {
                            headers: {
                                'Content-Type': 'text/xml'
                            }
                        }
                    ).then((response2) => {
                        let uf = response2.data.data.items;
                        console.log(uf);
                        Tself.abstract = uf.abstract;
                        Tself.dependencies = uf.dependencies;
                        Tself.environment = uf.environment;
                        Tself.functionList = uf.functionList;
                        Tself.functionNumber = uf.functionNumber;
                        Tself.hierarchyDiagramPath = uf.hierarchyDiagramPath;
                        Tself.hierarchyList = uf.hierarchyList;

                        // 获取用户投票记录
                        let data = {
                            relatedId: Tself.jid,
                            type: "MoreInformation",
                            userId: Tself.getCookie('user_id')
                        }
                        Tself.$axios({
                                method: 'post',
                                url: "/vote/getVoteRecord",
                                data: data,
                            }
                        ).then((response) => {
                            let data = response.data.data.items;
                            if (data === undefined || data === null || data === "") {
                                Tself.voteAlready = false
                            } else {
                                Tself.voteAlready = true
                                Tself.choice = data.choice
                            }
                        }).catch((error) => {
                            console.log(error);
                        })

                        Tself.getVoteInforamtion();


                    }).catch((error) => {
                        console.log(error.message);
                    })

                }).catch(function (error) {
                    console.log(error.message);
                });
            },

            // 首先要判断是否投过票，已经投过按钮要灰掉
            // 然后获取投票数据，显示投票状况
            getVoteRecord() {
                let Tself = this
                let data = {
                    relatedId: Tself.jid,
                    type: "MoreInformation",
                    userId: Tself.getCookie('user_id')
                }
                Tself.$axios({
                        method: 'post',
                        url: "/vote/getVoteRecord",
                        data: data,
                    }
                ).then((response) => {
                    let data = response.data.data.items;
                    if (data === undefined || data === null || data === "") {
                        Tself.voteAlready = false
                    } else {
                        Tself.voteAlready = true
                        Tself.choice = data.choice
                    }
                }).catch((error) => {
                    console.log(error);
                })
            },

            getVoteCommentData() {
                this.$axios({
                        method: 'post',
                        url: "/vote/getVoteComment",
                        data: {
                            voteId: this.voteId,
                        }
                    }
                ).then((response) => {
                    let data = response.data.data.items;
                    this.voteCommentData = data;
                }).catch((error) => {
                    console.log(error);
                })
            },

            getVoteInforamtion() {
                let data = {
                    relatedId: this.jid,
                    type: "MoreInformation",
                }
                this.$axios({
                        method: 'post',
                        url: "/vote/getVoteInformation",
                        data: data,
                    }
                ).then((response) => {
                    let data = response.data.data.items;
                    console.log(data)
                    this.voteId = data.id;
                    this.approve = data.approve;
                    this.reject = data.reject;
                    this.voteState = data.state;
                    this.startTime = data.startTime;
                    this.finishTime = data.finishTime;
                    this.state = data.state;
                    if (this.state==="Done"){
                        this.voteAlready = true;
                        Object.freeze(this.voteAlready)
                    }
                    this.getVoteCommentData();
                }).catch((error) => {
                    console.log(error);
                })
            },


            confer1() {
                this.modal1 = true;
            },
            confer2() {
                this.modal2 = true;
            },
            confer3() {
                this.modal3 = true;
            },
            confer4() {
                this.modal4 = true;
            },

            myApprove() {
                this.approveModal = true;
            },
            myReject() {
                this.rejectModal = true;
            },
            addComment() {
                this.commentModal = true;
            },
            commentOk() {
                let data = {
                    voteId: this.voteId,
                    userId: this.getCookie('user_id'),
                    content: this.commentContent,
                }
                this.$axios({
                        method: 'post',
                        url: "/vote/addVoteComment",
                        data: data,
                    }
                ).then((response) => {
                    let data = response.data.data.items;
                    if (data === "comment success") {
                        this.$Message.success(data)
                        this.getVoteCommentData()
                    } else {
                        this.$Message.error(data)
                    }
                }).catch((error) => {
                    console.log(error);
                })

            },

            ok() {
                this.$axios({
                        method: 'post',
                        url: "/moreInformation/agree",
                        data: (this.$route.query.jid),
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        //moreInformation

                    }
                ).then((response) => {
                    console.log(response)
                    this.modal = false;
                    this.form.status = true;
                    this.end_data = new Date(Date.now() + (5 * 60 * 1000));
                }).catch((error) => {
                    console.log(error.message);
                })

            },

            approveOk() {
                let Tself = this;
                let voteData = {
                    relatedId: this.jid,
                    choice: "approve",
                    type: "MoreInformation",
                    userId: this.getCookie('user_id')
                }
                this.$axios({
                        method: 'post',
                        url: "/vote/submitVote",
                        data: voteData
                    }
                ).then((response) => {
                    let data = response.data.data.items;
                    if (data === "vote success") {
                        Tself.$Message.success(data)
                        this.voteAlready = true
                        this.choice = "approve"
                        this.getVoteInforamtion()
                    } else {
                        Tself.$Message.error(data)
                    }

                }).catch((error) => {
                    console.log(error);
                })
            },

            rejectOk() {
                let Tself = this;
                let voteData = {
                    relatedId: this.jid,
                    choice: "reject",
                    type: "MoreInformation",
                    userId: this.getCookie('user_id')
                }
                this.$axios({
                        method: 'post',
                        url: "/vote/submitVote",
                        data: voteData
                    }
                ).then((response) => {
                    let data = response.data.data.items;
                    if (data === "vote success") {
                        Tself.$Message.success(data)
                        this.voteAlready = true
                        this.choice = "reject"
                        this.getVoteInforamtion()
                    } else {
                        Tself.$Message.error(data)
                    }

                }).catch((error) => {
                    console.log(error);
                })
            },

            ok1() {
                this.$axios({
                        method: 'post',
                        url: "/moreInformation/reply",
                        data: {
                            id: this.$route.query.jid, //moreInformation
                            reply: this.further_reason1,
                            replyUserId: this.getCookie('user_id')
                        }
                    }
                ).then((response) => {
                    this.modal1 = false;
                }).catch((error) => {
                    console.log(error.message);
                })
            },
            ok2() {
                this.$axios({
                        method: 'post',
                        url: "/moreInformation/reply",
                        data: {
                            id: this.$route.query.jid, //moreInformation
                            reply: this.further_reason2,
                            replyUserId: this.getCookie('user_id')
                        }
                    }
                ).then((response) => {
                    this.modal2 = false;
                }).catch((error) => {
                    console.log(error.message);
                })
            },
            ok3() {
                this.$axios({
                        method: 'post',
                        url: "/moreInformation/reply",
                        data: {
                            id: this.$route.query.jid, //moreInformation
                            reply: this.further_reason3,
                            replyUserId: this.getCookie('user_id')
                        }
                    }
                ).then((response) => {
                    this.modal3 = false;
                }).catch((error) => {
                    console.log(error.message);
                })
            },
            ok4() {
                this.$axios({
                        method: 'post',
                        url: "/moreInformation/reply",
                        data: {
                            id: this.$route.query.jid, //moreInformation
                            reply: this.further_reason4,
                            replyUserId: this.getCookie('user_id')
                        }
                    }
                ).then((response) => {
                    this.modal4 = false;
                }).catch((error) => {
                    console.log(error.message);
                })
            },
            cancel() {

            },
            return_list() {
                this.$router.push("/checkFurtherList?id=" + this.projectId);
            },
            getCookie(cname) {
                let name = cname + "=";
                let ca = document.cookie.split(';');
                for (let i = 0; i < ca.length; i++) {
                    let c = ca[i].trim();
                    if (c.indexOf(name) !== -1) {
                        return c.substring(name.length, c.length);
                    }
                }
                return "";
            },
            popo() {
                if (this.form.abs_content !== '') {
                    this.form.abs_type = "For more information"
                    this.tp1 = 1;
                } else if (this.form.abs_modify !== '') {
                    this.form.abs_type = "Modify"
                    this.tp1 = 1;
                }

                if (this.form.env_content !== '') {
                    this.form.env_type = "For more information"
                    this.tp2 = 1;
                } else if (this.form.env_modify !== '') {
                    this.form.env_type = "Modify"
                    this.tp2 = 1;
                }
                if (this.form.dep_content !== '') {
                    this.form.dep_type = "For more information"
                    this.tp3 = 1;
                } else if (this.form.dep_modify !== '') {
                    this.form.dep_type = "Modify"
                    this.tp3 = 1;
                }
                if (this.form.func_content !== '') {
                    this.form.func_type = "For more information"
                    this.tp4 = 1;
                } else if (this.form.func_modify.length !== 0) {
                    this.form.func_type = "Modify"
                    this.tp4 = 1;
                }
                if (this.form.price_content !== '') {
                    this.form.price_type = "For more information"
                    this.tpPrice = 1;
                } else if (this.form.price_modify.length !== 0) {
                    this.form.price_type = "Modify"
                    this.tpPrice = 1;
                }
            }


        },
        mounted() {
            this.initial_data();
        }
    }
</script>

<style scoped>
    .vote_information {
        margin-bottom: 10px;
    }

    .vote_information_item {
        margin-right: 3px;
        text-align: right;
    }

</style>

